import { PropsWithChildren } from 'react';

import { Typography, Card, Box, useTheme, Divider } from '@mui/material';
import Image from 'next/image';
import { IImage } from '@/shared/types';
import { redirectToAccount } from '@/utils';

type ChooseLoanAmountFormProps = {
  title: string;
  bannerMobile?: IImage;
} & PropsWithChildren;

export const LoanCalculatorItemRoot = ({
  title,
  bannerMobile,
  children,
}: ChooseLoanAmountFormProps) => {
  const theme = useTheme();

  const handleBannerClick = () => {
    redirectToAccount();
  };

  const styles = {
    card: {
      border: {
        xs: `1px solid ${theme.palette.neutral['90']}`,
        md: 'none',
      },
      bgcolor: {
        xs: 'neutral.100',
        md: 'common.white',
      },
    },
    divider: {
      display: 'none',
    },
    heading: {
      color: undefined,
      display: 'none',
      variant: 'headline6',
    },
  } as const;

  return (
    <Card
      sx={{
        p: {
          xs: 2.5,
          md: 3,
        },
        height: '100%',
        borderRadius: { xs: 0, md: 1.875 },
        backgroundColor: styles.card.bgcolor,
        border: styles.card.border,
      }}
    >
      <Box
        sx={{
          margin: 'auto',
          maxWidth: {
            xs: 450,
            md: 'unset',
          },
        }}
      >
        {bannerMobile?.data?.attributes?.url ? (
          <Box
            mt={-2.5}
            mb={2}
            onClick={handleBannerClick}
            display={{
              xs: 'block',
              md: 'none',
            }}
          >
            <Image
              src={bannerMobile?.data?.attributes?.url}
              alt={bannerMobile?.data?.attributes?.alternativeText || ''}
              height={bannerMobile?.data?.attributes?.height}
              width={bannerMobile?.data?.attributes?.width}
              style={{
                height: 'auto',
              }}
            />
          </Box>
        ) : null}
        <Typography
          color={styles.heading.color}
          display={styles.heading.display}
          mb={2.25}
          textAlign="center"
          variant={styles.heading.variant}
        >
          {title}
        </Typography>
        <Divider
          sx={{
            borderColor: 'neutral.90',
            borderStyle: 'dashed',
            display: styles.divider.display,
            my: 2,
          }}
        />
        {children}
      </Box>
    </Card>
  );
};
