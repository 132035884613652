import { useEffect, useRef, useState } from 'react';

import { Box, Slider, Stack, Typography, useTheme } from '@mui/material';
import { useDebounce } from 'use-debounce';
import { useTranslations } from 'next-intl';
import { THomeBlockFirstLoanCalculator } from '@/shared/api/homepage-api';
import { useConfig } from '@/shared/hooks/use-config';
import { GradientButton, InputPhone, PreventCloseGesture } from '@/shared/ui';
import {
  getPhoneInputParentElement,
  LOAN_CALCULATOR_PHONE_INPUT_PARENT_NODE_ID,
  redirectToAccount,
  useFormatters,
} from '@/utils';
import {
  AB_TESTING_REDIRECT_STORAGE_KEY,
  LANDING_PHONE_NUMBER_STORAGE_KEY,
  LANDING_REFERRER_STORAGE_KEY,
  LANDING_URL_STORAGE_KEY,
  FIRST_VISIT_ID_STORAGE_KEY,
  CURRENT_VISIT_ID_STORAGE_KEY,
} from '@/shared/constants';
import {
  LoanCalculatorItemRoot,
  CalculatorOutputValues,
  SumChip,
  ManualSumInput,
} from './ui';
import {
  addChangeAmountAction,
  clearChangeAmountActions,
  getChangeAmountActions,
} from './analytics';
import { useBoolean } from '@/shared/hooks';
import { ABTestingBlockNames, setToLocalStorage } from '@/features/ab-testing';
import { encodeLandingInfo, useUtmParams } from '@/shared/lib';
import { useCalculatorStore } from '@/stores/calculator/calculator-store-provider';
import { roundValueToStep } from '@/entities/first-loan-calculator/utils';
import Image from 'next/image';

type FirstLoanCalculatorProps = Pick<
  THomeBlockFirstLoanCalculator,
  'calculator'
>;

export const FirstLoanCalculatorWithSlider = (
  props: FirstLoanCalculatorProps,
) => {
  const { calculator } = props;
  const theme = useTheme();

  const styles = {
    loanAmountTitle: {
      color: 'neutral.20',
      variant: 'headline5',
    },
    loanAmountValue: {
      color: 'neutral.20',
      variant: 'headline4',
    },
    loanAmountSlider: {
      height: 12,
    },
    loanAmountSliderThumb: {
      height: 24,
      width: 24,
    },
    chipText: {
      variant: 'text7',
      bgcolor: {
        xs: 'common.white',
        md: 'neutral.95',
      },
    },
    phoneInputBaseRoot: {
      bgcolor: 'neutral.95',
      boxShadow: `0 0 0 1px ${theme.palette.neutral[95]}`,
    },
    bigShowAmountOutputBlock: {
      display: 'none',
    },
    smallShowAmountOutputBlock: {
      display: 'block',
    },
    leftTopGreenTitle: {
      display: {
        xs: 'none',
        md: 'block',
      },
    },
  } as const;

  const {
    bannerMobile,
    title,
    buttonGradientAnimation,
    min,
    max,
    defaultValue,
    step,
    multiplicationFactor,
    buttonText,
    phoneInputPlaceholder,
    showAmountOutput,
    minSumForRepeatOnlyLoans,
    loanAmountTitle,
    repeatOnlyText,
    finalSumFootnote,
    security,
  } = calculator;

  const t = useTranslations();

  const config = useConfig();
  const {
    formatCurrency,
    formatPhoneNumber,
    formatNumberWithCommonApiContextSeparator,
  } = useFormatters();
  const utmParams = useUtmParams();
  const isTouchedAmount = useBoolean(false);
  const [amount, setAmount] = useState<number>(defaultValue);
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [inputError, setInputError] = useState(false);
  const [errorCode, setErrorCode] = useState<string>();
  const inputPhoneRef = useRef<HTMLDivElement>(null);
  const [debouncedAmount] = useDebounce(amount, 700);
  const { off, setFocused, highlightPhoneNumber } = useCalculatorStore(
    (state) => state,
  );

  useEffect(() => {
    const amountActions = getChangeAmountActions();

    if (!amountActions?.length) {
      addChangeAmountAction(amount);
    }
  }, []);

  useEffect(() => {
    if (isTouchedAmount.value) {
      addChangeAmountAction(debouncedAmount);
    }
  }, [debouncedAmount, isTouchedAmount.value]);

  const errorMessageByCode: Record<string, string> = {
    countdown_error: t('errors.phoneNumber.countdown_error'),
  };
  const helperText = errorCode
    ? errorMessageByCode[errorCode] || t('errors.phoneNumber.error')
    : null;

  const setFocusToPhoneInput = () => {
    const input = inputPhoneRef?.current?.querySelector('input');
    if (input) {
      input.focus();
    }
  };

  const setPhoneInputError = () => {
    // TODO: investigate why inputRef prop does not working
    setInputError(true);
    setFocusToPhoneInput();
  };

  useEffect(() => {
    if (highlightPhoneNumber) {
      setFocusToPhoneInput();
      off();
    }
  }, [highlightPhoneNumber]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (phoneNumber.length < 2) {
      setPhoneInputError();
      return;
    }

    const formattedPhoneNumber = formatPhoneNumber(phoneNumber);

    setInputError(false);

    sessionStorage.setItem(
      LANDING_PHONE_NUMBER_STORAGE_KEY,
      formattedPhoneNumber,
    );

    fetch('/api/getCode', {
      method: 'POST',
      body: JSON.stringify({
        landing_page_actions: getChangeAmountActions(),
        landing_url: localStorage.getItem(LANDING_URL_STORAGE_KEY),
        phoneNumber: formattedPhoneNumber,
        referrer: localStorage.getItem(LANDING_REFERRER_STORAGE_KEY),
        first_visit_id: localStorage.getItem(FIRST_VISIT_ID_STORAGE_KEY),
        current_visit_id: sessionStorage.getItem(CURRENT_VISIT_ID_STORAGE_KEY),
        user_agent: navigator.userAgent,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          setErrorCode(data.error.code);
          setPhoneInputError();
        } else {
          clearChangeAmountActions();

          setToLocalStorage(
            AB_TESTING_REDIRECT_STORAGE_KEY,
            ABTestingBlockNames.Calculator,
          );

          const encodedLandingInfo = encodeLandingInfo();

          const params = new URLSearchParams();
          params.append('authId', data.data.auth_id);
          params.append('sourceFrom', 'landing');
          params.append('landingInfo', encodedLandingInfo);

          for (const key in utmParams) {
            if (utmParams.hasOwnProperty(key)) {
              params.append(key, utmParams[key].toString());
            }
          }

          redirectToAccount(`/auth?${params.toString()}`);
        }
      })
      .catch(() => {
        setErrorCode('unknown');
        setPhoneInputError();
      });
  };

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputError(false);

    const phoneInputElementParent = getPhoneInputParentElement();

    if (phoneInputElementParent) {
      phoneInputElementParent.style.boxShadow = '';
    }

    setPhoneNumber(e.currentTarget.value);
  };

  const handleAmountChange = (_: unknown, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      isTouchedAmount.on();
      setAmount(newValue);
    }
  };

  const handleManualSumInputChange = (value: number) => {
    if (value < min) {
      setAmount(min);
    } else if (value > max) {
      setAmount(max);
    } else {
      setAmount(roundValueToStep(step, value));
    }
  };

  const showRepeatLoanOnlyChip = amount >= minSumForRepeatOnlyLoans;

  const inputSx = {
    '& .MuiInputBase-root': {
      boxShadow: styles.phoneInputBaseRoot.boxShadow,
      bgcolor: styles.phoneInputBaseRoot.bgcolor,
      overflow: 'auto',

      '&:hover': {
        bgcolor: styles.phoneInputBaseRoot.bgcolor,
      },
    },
  };

  return (
    <LoanCalculatorItemRoot title={title} bannerMobile={bannerMobile}>
      <Box component="form" onSubmit={handleSubmit}>
        <Typography
          display={styles.leftTopGreenTitle.display}
          color="primary"
          variant="text5"
        >
          {title}
        </Typography>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mb={{
            xs: 0,
            md: 0.75,
          }}
        >
          <Typography
            color={styles.loanAmountTitle.color}
            variant={styles.loanAmountTitle.variant}
          >
            {loanAmountTitle}
          </Typography>
          {config?.countryCode === 'LK' || config?.countryCode === 'MY' ? (
            <ManualSumInput
              onChange={handleManualSumInputChange}
              baseValue={amount}
            />
          ) : (
            <Typography
              color={styles.loanAmountValue.color}
              variant={styles.loanAmountValue.variant}
            >
              {formatCurrency(amount)}
            </Typography>
          )}
        </Stack>
        <Box mb={3.5}>
          <PreventCloseGesture>
            <Slider
              aria-label={loanAmountTitle}
              defaultValue={defaultValue}
              step={step}
              min={min}
              max={max}
              valueLabelDisplay="off"
              marks={false}
              value={amount}
              onChange={handleAmountChange}
              sx={{
                color: theme.palette.common.primary,
                height: styles.loanAmountSlider.height,

                '& .MuiSlider-rail': {
                  color: theme.palette.neutral[90],
                },
                '& .MuiSlider-thumb': {
                  height: styles.loanAmountSliderThumb.height,
                  width: styles.loanAmountSliderThumb.width,
                  backgroundColor: theme.palette.common.primary,
                  '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
                    boxShadow: 'inherit',
                  },
                  '&:hover': {
                    boxShadow: '0 0 0 8px rgba(255, 255, 255, 0.46)',
                  },
                  '&:before': {
                    display: 'none',
                  },
                },
              }}
            />
          </PreventCloseGesture>

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            mb={{
              xs: 1,
              md: 0,
            }}
            sx={{ minHeight: 24 }}
          >
            <SumChip variant={styles.chipText.variant}>
              {formatNumberWithCommonApiContextSeparator(min)}
            </SumChip>
            {showRepeatLoanOnlyChip ? (
              <Typography
                variant={styles.chipText.variant}
                sx={(theme) => ({
                  background: theme.palette.primary[95],
                  color: theme.palette.common.primary,
                  p: theme.spacing(0.5, 1.25),
                  borderRadius: 0.625,
                })}
              >
                {repeatOnlyText}
              </Typography>
            ) : null}

            <SumChip variant={styles.chipText.variant}>
              {formatNumberWithCommonApiContextSeparator(max)}
            </SumChip>
          </Stack>
        </Box>
        <InputPhone
          id={LOAN_CALCULATOR_PHONE_INPUT_PARENT_NODE_ID}
          placeholder={phoneInputPlaceholder}
          onChange={handlePhoneNumberChange}
          onFocus={() => {
            setFocused(true);
          }}
          onBlur={() => {
            setFocused(false);
          }}
          value={phoneNumber}
          error={inputError}
          helperText={helperText}
          ref={inputPhoneRef}
          sx={inputSx}
        />
        {showAmountOutput ? (
          <Box display={styles.bigShowAmountOutputBlock.display}>
            <CalculatorOutputValues
              amount={amount}
              finalSumFootnote={finalSumFootnote}
              multiplicationFactor={multiplicationFactor}
            />
          </Box>
        ) : null}
        <GradientButton
          type="submit"
          animate={buttonGradientAnimation}
          size="large"
          sx={{
            p: 2.25,
            mt: 1.25,
          }}
          fullWidth
        >
          {buttonText}
        </GradientButton>

        {showAmountOutput ? (
          <Box mt={2} display={styles.smallShowAmountOutputBlock.display}>
            <Stack
              direction="row"
              alignItems="flex-end"
              justifyContent="space-between"
              spacing={1}
            >
              <Typography color="neutral.50" variant="text5">
                {finalSumFootnote}
              </Typography>

              <Typography
                color="neutral.20"
                variant="headline7"
                flex="none"
                component="div"
                alignSelf="self-start"
              >
                {formatCurrency(amount * multiplicationFactor)}
              </Typography>
            </Stack>
          </Box>
        ) : null}

        {security ? (
          <Box
            mt={3}
            sx={{
              borderRadius: 1.25,
              p: ({ spacing }) => spacing(1.5, 5),
              border: '1px solid #08BE66',
            }}
          >
            <Box display="flex" alignItems="center" gap={1}>
              <Image
                src={security.icon?.data?.attributes.url}
                alt={
                  security.icon?.data?.attributes.alternativeText ||
                  'security_icon'
                }
                width={36}
                height={36}
              />

              <Typography color="neutral.50" variant="text8">
                {security.text}
              </Typography>
            </Box>
          </Box>
        ) : null}
      </Box>
    </LoanCalculatorItemRoot>
  );
};
