import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { Input } from '@/shared/ui/input';
import { useConfig } from '@/shared/hooks';
import { useDebounce } from 'use-debounce';

type Props = {
  onChange: (arg0: number) => void;
  baseValue: number;
};

export const ManualSumInput = ({ onChange, baseValue }: Props) => {
  const config = useConfig();

  const [value, setValue] = useState<string>();

  const [debouncedAmount] = useDebounce(value, 1200);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value.replace(/\D/g, ""));
  };

  useEffect(() => {
    if(debouncedAmount?.length) {
      onChange(Number(debouncedAmount));
    }
  }, [debouncedAmount]);

  useEffect(() => {
    setValue(baseValue.toString());
  }, [baseValue]);

  return (
    <Input
      value={value}
      onChange={handleChange}
      InputProps={{
        endAdornment: (
          <Typography variant="headline9" textTransform="uppercase"
                      whiteSpace="nowrap">| {config?.currency}</Typography>
        ),
      }}
      sx={{
        width: 121,
        '& input': {
          py: 0.625,
          px: 0.875,
        },
      }}
    />
  );
};
