import React from 'react';
import { Typography, TypographyPropsVariantOverrides } from '@mui/material';

interface SumChipProps {
  children: React.ReactNode | React.ReactNode[];
  sx?: object;
  variant: keyof TypographyPropsVariantOverrides;
}

export const SumChip = ({
  children,
  sx = {},
  variant = 'headline9',
}: SumChipProps) => {
  return (
    <Typography
      variant={variant}
      sx={(theme) => ({
        background: theme.palette.neutral[95],
        color: theme.palette.neutral[50],
        p: theme.spacing(0.625, 1.75),
        borderRadius: 0.625,
        ...sx,
      })}
    >
      {children}
    </Typography>
  );
};
